@include media-breakpoint-up(lg) {
	.container-lg {
		max-width: 1160px;
	}
}
.row{
	.row-center{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}