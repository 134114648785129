.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("subastas") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-cba {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-cba--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-cba--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-cba--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("cordoba") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-sl {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-sl--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-sl--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-sl--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("sanluis") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-er {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-er--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-er--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-er--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("entrerios") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-lp {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-lp--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-lp--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-lp--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("lapampa") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-jujuy {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-jujuy--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-jujuy--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-jujuy--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("jujuy") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-salta {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-salta--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-salta--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-salta--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("salta") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-chaco {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-chaco--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-chaco--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-chaco--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("chaco") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-jugob {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-jugob--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-jugob--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-jugob--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("jugob") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-aduana {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-aduana--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-aduana--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-aduana--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("aduana") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-nssa {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-nssa--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-nssa--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-nssa--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("nssa") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.headroom-test {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}

.headroom-test--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom-test--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom-test--not-top {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: theme-color("test") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}