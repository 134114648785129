 
.nav-item{
    cursor: pointer;
    .nav-link{
        padding-left:2rem;
        padding-right:2rem;
        font-size: 18px;
        &.tab-active{
            color: #fff!important;
            background-color: #000;
             
        }
    }

}
.tab-container{
    padding-left: 1rem;
    padding-right: 1rem;
    .nav-tabs{
        column-gap: 5px;
    }
    @include media-breakpoint-down(lg){
        padding-left: 0;
        padding-right: 0;
    }
}
