
@use "sass:map";

@import '../../assets/scss/argon-design-system-react.scss' ;
.tip-card{
   
    margin-bottom: 1.5rem ;
    .tip-card__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        @include media-breakpoint-down(xs){
            flex-direction: column;
            justify-content: center;
            row-gap: 10px;
        }
        .tip-card__header__title{
            margin: 0;
            h5{
                margin: 0;
            }
        }
    }
    .tip-card__body{
        @include media-breakpoint-down(xs){
           ul,ol{
               padding-left: 1rem;
           } 
         }
    }
}
.tip-card--shadow{
    box-shadow: 5px 5px 5px map-get($colors , "lighter" );
}

.payment-help{
    margin-bottom: 20px;
    .payment-help__title{
        color:white;
        margin-bottom: 0;
    }
    p{
        b{
            color: #0d47a1;
        }
    }
}