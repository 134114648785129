@use "sass:map";

@import '../../assets/scss/argon-design-system-react.scss' ;
.element-style{
    position: absolute;
    will-change: absolute;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 36px, 0);
}

.dropdown-menu{
    min-width: 13rem;
}

.text-center{
    text-align: center !important;
}
.share-block{


    /** Media queries container hasta 1200px */ 
    :global{
        .dropdow-share {
            display: none;
        }            
        @include media-breakpoint-down(lg){
            .dropdown{
                
                    width:100%;
                    .btn-sm{
                        width:100%
                    }
                
            }


        }
    }
    @include media-breakpoint-down(lg){
        .share{
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
     /** Media queries container hasta 992px */ 
     :global{
           
        @include media-breakpoint-down(md){
            .dropdown{
                
                    width:100%;
                    .btn-sm{
                        width:100%;
                        max-width: 190px;
                        margin: 10px auto;                        
                    }
                
            }


        }
    }

     /** Media queries container hasta 768px */ 
     :global{
           
        @include media-breakpoint-down(sm){
        .atcb-initialized{
            width: 100%;
            .atcb-button-wrapper{
                width: 100%;
                button{
                    width: 100%;
                }
            }
        }


        }
    }     
    :global{
           
        @include media-breakpoint-down(xs){
            .atcb-initialized{
                width: auto;
                margin: 10px auto;
                .atcb-button-wrapper{
                    width: auto;
                    button{
                        width: auto;
                    }
                }
            }
            .dropdown{
                    
                width:100%;
                .btn-sm{
                    display: block;                      
                }
            
            }
            .dropdow-share.dropdown-menu {
                float:none;
                max-width: 236px;
                margin: 0 auto;      
                position: absolute!important;        
                margin-left: auto;
                margin-right: auto;
                right: 0;
            }

        }
    }      
}
:global{
    .dropdow-share{
        transform: none!important;
        top: 100%!important;
        width: fit-content;
        &.dropdown-menu{
            z-index: 1000;
            position: absolute!important;
            right: 0;
            padding: 0.5 rem 0.5rem;
            margin: 0.125rem 0 0;
            font-size: 1rem;
            color: #525f7f;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 0 solid rgba(0,0,0,0.15);
            border-radius: 0.3rem;
            text-align: center;
            border: 1;
            &.show{
                    padding: 0.5rem 0.5rem;
                    display: flex;
                    justify-content: center;
                    column-gap: 5px;
                    @include media-breakpoint-up(lg){
                        left:calc(calc(-100%) - 15px)!important;
                    }
                    @include media-breakpoint-down(lg){
                        left:calc(calc(-100%) - 30px)!important;
            
                    }            
                    @include media-breakpoint-down(md){
                       
                            left:calc(calc(-3%) - 30px)!important;
                        
            
                    }        
                    @include media-breakpoint-down(sm){
                       
                        left:-45px !important
                    
        
                    }                                  
                    @include media-breakpoint-down(xs){
                       
                        left:0%!important
                    
        
                    }                      
                }
            }
              
    }
}

.buttons-size{
    width: 100%;
    box-shadow: rgba(0, 0, 0,  0.3) 2px 5px 18px -1px, rgba(0, 0, 0, 0.25) 2px 2px 10px -3px;
}

.share{
    color: black;
    height: 2.5rem;
}

.share2{
    color: green;
    height: 2.5rem;
}
.copy-link{
    width: 32px;
    height: 32px;
    color: white;
}


:global{
    .wrapper-adtb{
        .atcb-button-wrapper{
            padding: 5px 0;
            margin-top: 0.1515rem;;
        }
        .atcb-button{
            width: 100%;
            height:2.5rem;
            border-radius: 0.25em;
            margin: 0;

        }
        .atcb-text{
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 0.75rem;
            letter-spacing: 0.025em;
            line-height: normal;
            color: black;
        }

    }
}
